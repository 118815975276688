@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h2 {
  color:  white;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
}

a {
  color:  #182A40;;
}

#work, #skills, #thisthat, #about, #contact {
  padding: 2rem 0;
}

#work h2, #contact h2 {
  color:  #aaa;
}

#navigation {
  background-color: #182A40;
  position: sticky !important;
  top: 0;
  z-index: 10;
  height: 70px;
  transition: .5s;
}

#navigation > div {
  width:  100%;
  height:  100%;
  color:  white;
}
#navigation > div > div {
  height:  100%;
}
#navigation.small {
  box-shadow: 0 5px 10px rgba(0,0,0,.35);
  height: 54px;
}

#navigation.small ul {
  height: 54px;
}

#navigation #logo {
  display: flex;
  align-items: center;
  height:  100%;
}

#navigation #logo img{
  transform:  translateY(10px);
  width: 100px;
  transition: .45s;
  position: absolute;
  }
#navigation #logo #logoname {
  text-transform: uppercase;
  color:  #6583a8;
  font-weight: 600;
  font-size: .875rem;
  transition: .45s;
  margin-left:  100px;
}
#navigation #logo #logoname span {
  color: #9fb8d8;
}

#navigation.small #logo #logoname {
  transform:  translateY(0);
  font-size:  .75rem;
  margin-left:  90px;
}


#navigation.small #logo{
  transform:  translateY(0);
}

#navigation.small #logo img {
  width:  80px;
  transform: translateY(10px);
  cursor: pointer;
}  
#navigation ul {
 text-align: right;
 position: relative;
 margin: 0;
 display: flex;
 align-items: center;
 flex: 1;
 flex-direction: row;
 justify-content: flex-end;
 align-items: center;
 height: 70px;

}

#navigation ul li {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom:  0;
  align-items: stretch;
  height:  100%;
  
}
#navigation ul li a.navlink {
  color:  #6583a8;
  text-transform: uppercase;
  text-decoration: none;
  font-size:  .875rem;
  padding: 1rem;
  transition: .5s;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  display:  flex;
  align-items: center;

}

#navigation .navlink-active {
  color: #3ccfff;
  background-color: rgba(255,255,255,.25);
}

#navigation ul a:hover {
  color:  #3ccfff;
  background-color: white;
} 

.hamburger {
  position: relative;
  width:50%;
   transition:all 0.5s ease;
}

.hamburger button {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  cursor: pointer;
  background:  #b9b9b9;
}

.#navigation .menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: darkcyan;
  right: 0;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
  transition:all 0.5s ease;
}


#navigation nav.hamburger ul.menuNav, #navigation nav.hamburger ul.menuNav li {
  display: none;
  transition:  all .5s ease;
}
#navigation nav.hamburger ul.menuNav.showMenu {
  display: list-item;;
  list-style: none;
  width: 100%;
  height:  auto;
  background-color: rgba(155,245,245,.9);
  position: fixed;
  right: 0;
  top: 0;
  padding-right: 80px;
  direction:  rtl;
}

#navigation nav.hamburger ul.menuNav.showMenu li{
  list-style: none;
  display: list-item;
  text-align: right;
  width: 50%;
  

}


#navigation nav.hamburger ul.menuNav.showMenu li a {
  color: #666;
  border-bottom: 1px dotted #999;
  text-align:  right;
  width: 100%;
  display: block;

}


#header {
  background: rgb(21,36,53);
  background: linear-gradient(310deg, rgba(21,36,53,1) 0%, rgba(41,71,106,1) 100%);
  height: 20vmax;
  display: flex;
  align-items: center;
  jusitfy-content: center;
}


#header h1 {
  color:  white;
  text-transform:  uppercase;
  text-shadow: 5px 5px 5px rgba(0,0,0,1);
  font-size: 6vmax;
  text-align: center;
  margin: 0;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
}
#header #strap {
  font-size:  1.35vmax;
  color: #45BBD8;
  text-align: center;
}
#work button#close {
  width:  50px;
  height:  50px;
  border-radius: 100%;
  font-size: 100%;
  border: 4px solid #ababab;
  color:  #ababab;
  background-color: #888;
  position: absolute;
  top:  0;
  right: 0  ;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}


#work, #contact {
  background-color: #efefef;
}
#work .project {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.7rem;

}
.closeup {
  border-radius: 0 3rem 0 0;
  background-color: #ddd;
  padding: 2rem;
}


#work .project .button {
  width:  100%;
  height:  100%;
  margin: 0;
  background-color: #ddd;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  text-align: center;
  transition:all .85s ease;
  -webkit-transition:all .85s ease;
  -o-transition:all .85s ease;
  -moz-transition:all .85s ease;
}
#work .project .button:hover {
  background-size: 130%;
  background-position: center center;
  transition:all 0.85s ease;
  -webkit-transition:all 0.85s ease;
  -o-transition:all 0.85s ease;
  -moz-transition:all 0.85s ease;
}

#work .project .overlay-block{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(26 , 36, 53, .9);
  transition: .5s;
  z-index: 9;
}
#work .project .overlay-block:hover {
  background-color: rgba(26,36,53,.4);
}

#work .project .project-type {
  pointer-events: none;
  font-size:  4rem;
  position:  absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255,255,255,.5);
  transition: .5s;
  z-index: 9;
}
#work .project .project-title {
  color:  rgba(255,255,255,.2);
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  bottom: 0%;
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: baseline;
  transform:  translateX(-50%);
  margin-left:  50%;
  line-height:  1.2;
  font-size:  .75rem;
  z-index: 11;
  justify-content: center;
  align-items: end;
  transition: all .5s ease;
}
#work .project .project-title:hover {
  color:  rgba(255,255,255,.9);
  font-size:  .825rem;
}

#work .project .button:hover {
  background-color: #aaa;
  background-image: none;
  cursor: pointer;
}

.videoWrapper {
  position: relative;
  padding-bottom: 62.25%; /* 16:9 */
  height: 0;
  margin-bottom: 1rem;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;


}

#work .project:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

#skills h3 {
  font-size:  1.5rem;
  font-weight: bold;
  line-height: 1;
  margin: .75rem 0 .5rem;
  color: #405a79;
}
#skills p {
  line-height: 2;
  margin: 0;
  color:  #777;
}

#dive img {
  position: relative;
  width:  100%;
  margin-bottom:  1.875rem;
 /* box-shadow: 0 10px 10px rgba(0,0,0,.2);*/
}

#work #dive h3 {
  font-size:  .75rem;
  text-transform: uppercase;
  margin: 0 0 .25rem;
  letter-spacing: 1px;
  font-weight: 900;
  color:  #405a79;
}
#work #dive p {
  color: #777;
  font-size: .9rem;
}


#thisthat, #footer {
  background-color: #45BBD8;
}
#thisthat .serious img {
  width:  100%;
  position: relative;
  transition:  .5s all ease;
}
#thisthat .serious img:hover {
  transform: rotate(7deg);
}

img.artwork {
   border-radius:  100%;
   width: 60%;
   height: auto;
}
#audio > div > div {
  padding:  0 !important;
  margin:  0;
}
#audio .audio-player {
  width:  90%;
  margin-bottom:  2rem;
}

#audio .track-info {
  width: auto;
  position: relative;
  text-align: center; 
  border-radius:  10px;
  padding: 2rem;
  box-shadow: 0px 10px 20px rgba(0,0,0,.2);
  background-color: rgba(255,255,255,.25);
}
#audio .track-info .title {
  text-align: center;
}
#audio .track-info .artist {
  text-align: center;
  font-size:  .875rem;
  font-weight: 300;
}
.audio-controls button {
  background-color: rgba(255,255,255,.5);
  border: none;
  border-radius: 100%;
  height: 12vw;
  width: 12vw;
}
.audio-controls button svg{
  width:  6vw;
  height:  6vw;
  fill:  white;
}
.track-info input[type="range"] {
  position: relative;
  width:  100%;
  height: 3rem;
}


#about h2, #skills h2{
  color:  #45BBD8;
}
#about h3, #skills h3 {
  font-size:  1.5rem;
  line-height: 1;
}

#about h3 {
  margin: 1rem 0 ;
  color:  #405a79;
}
#about h4 {
  font-size: 1.125rem;
  margin:  1rem 0 1rem;
  color:  #999;
  margin-bottom: .5rem;
}
#about H4 + p {
  font-size:  .875rem;

}
#about img {
  border-radius: 100%;
  width:  70%;
  height:  auto;
}


#work p, #about p, #thisthat p, ul li {
  font-size:  1rem;
  line-height: 1.4;
  margin: 0 0 1rem 0;
}

#contactform input, #contactform textarea {
  width: 90%;
  border-radius: 4px;
  padding: 1.5em 1em;
  margin: .5em 0;
  border-style: none;
}
#contactform textarea {
  height: 8em;
}
#contactform input[type=submit] {
  width: 20%;
  color:  white;
  background-color: #ccc;
  transition: .5s;
}

#contactform input[type=submit]:hover{
  background-color:  #333;
}

#footer {
  min-height:  18vh;
  height:  100%;
  display: flex;
  align-items: center;
  padding-top:  1rem;
  padding-bottom:  2rem;
}

#footer p {
  font-size:  1rem;
}
#footer #reactlogo, #footer #reactinfo {
  width:  100%;
 display: flex;
 align-items: center;
}
#footer #reactlogo img {
  width: 100%;
}

#footer #reactinfo p{
  font-style: italic;
  font-size: .75rem;
  color:  #333;
}


@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}


@media only screen and (min-width: 576px) {

.audio-controls button {
  background-color: rgba(255,255,255,.5);
  border: none;
  border-radius: 100%;
  height: 6vw;
  width: 6vw;
}
.audio-controls button svg{
  width:  3vw;
  height:  3vw;
  fill:  white;
}
}

@media only screen and (min-width: 1200px) {

  #work #dive h3 {
    font-size:  1rem;
  }
  #work #dive p {
    font-size:  1rem;
  }
  #work button#close {
  width:  80px;
  height:  80px;

}
}

/*.App-link {
  color: #61dafb;
}*/

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/